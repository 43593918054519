import React, { Component } from 'react';
class Responsive extends Component {
	render() {
		const { device, children } = this.props;
		return (
			<div className={`Responsive-${device}`}>
				{ children }
			</div>
		);
	}
}

export default Responsive;
