import React, { Component } from "react"
import Responsive from "../../libraries/responsive"
import route from "../../libraries/route"
import images from "../../assets/images"
import Hamburger from "react-hamburgers"
import { CSSTransition } from "react-transition-group"
import { Link } from "gatsby"

class TopMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showMenu: false,
    }
  }
  render() {
    const { showMenu } = this.state
    return (
      <div className="home">
        <Responsive device="desktop">
          <header className="top-menu-desktop">
            <section className="logo">
              <Link to={route("root")}>
                <img alt="logo" src={images.logo} />
              </Link>
            </section>
            <nav>
              <ul>
                <li>
                  <Link exact="true" to={route("root")}>
                    HOME
                  </Link>
                </li>
                <li>
                  <Link to={route("products")}>PRODOTTI</Link>
                </li>
                <li>
                  <Link to={route("story")}>STORIA</Link>
                </li>
                <li>
                  <Link to={route("erica")}>ERICA</Link>
                </li>
                <li>
                  <Link to={route("contacts")}>CONTATTI</Link>
                </li>
                <li>
                  <a
                    href="http://www.facebook.com/lericas?ref=ts&fref=ts"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="facebook-link"
                  >
                    <img
                      alt="img"
                      src={images.facebook}
                      width="35"
                      height="35"
                      border="0"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/luigilericas/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="img"
                      src={images.instagram}
                      width="35"
                      height="35"
                      border="0"
                    />
                  </a>
                </li>
              </ul>
            </nav>
          </header>
        </Responsive>
        <Responsive device="not-desktop">
          <header className="top-menu-mobile">
            <div>
              <section className="logo">
                <Link to={route("root")}>
                  <img alt="logo" src={images.logo} />
                </Link>
              </section>
              <section>
                <Hamburger
                  active={showMenu}
                  type="spring"
                  onClick={() => this.setState({ showMenu: !showMenu })}
                />
              </section>
            </div>
            <CSSTransition
              in={showMenu}
              timeout={300}
              classNames="top-menu-mobile-links"
              unmountOnExit
            >
              <nav
                onClick={() => {
                  this.setState({ showMenu: false })
                }}
              >
                <ul>
                  <li>
                    <Link exact="true" to={route("root")}>
                      HOME
                    </Link>
                  </li>
                  <li>
                    <Link to={route("products")}>PRODOTTI</Link>
                  </li>
                  <li>
                    <Link to={route("story")}>STORIA</Link>
                  </li>
                  <li>
                    <Link to={route("erica")}>ERICA</Link>
                  </li>
                  <li>
                    <Link to={route("contacts")}>CONTATTI</Link>
                  </li>
                  <li>
                    <a
                      href="http://www.facebook.com/lericas?ref=ts&fref=ts"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="facebook-link"
                    >
                      <img
                        alt="img"
                        src={images.facebook}
                        width="30"
                        height="30"
                        border="0"
                      />
                    </a>
                    <a
                      href="https://www.instagram.com/luigilericas/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt="img"
                        src={images.instagram}
                        width="30"
                        height="30"
                        border="0"
                      />
                    </a>
                  </li>
                </ul>
              </nav>
            </CSSTransition>
          </header>
        </Responsive>
      </div>
    )
  }
}

export default TopMenu
