import { formatRoute } from "react-router-named-routes"

const root = "/"
const products = "/prodotti"
const story = "/storia"
const contacts = "/contatti"
const erica = "/erica"
const product = `${products}/:productID`

const routes = {
  root,
  products,
  story,
  contacts,
  erica,
  product,
}
export default function(routeName, routeProps = {}) {
  const selectedRoute = routes[routeName]
  if (!selectedRoute) {
    throw new Error(`Can't find route for route name '${routeName}'`)
  }
  return formatRoute(selectedRoute, routeProps)
}
