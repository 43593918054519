import React, { Component } from "react"
import Responsive from "../../libraries/responsive"
import route from "../../libraries/route"
import images from "../../assets/images"
import { Link } from "gatsby"

class BottomMenu extends Component {
  render() {
    return (
      <div className="home">
        <Responsive device="desktop">
          <footer className="bottom-menu-desktop">
            <section className="logo">
              <Link to={route("root")}>
                <img src={images.logo} alt="img" />
              </Link>
            </section>
            <section className="title">
              <h2>Lericas</h2>
              <h3>di Strazza Luigi</h3>
            </section>
            <nav>
              <ul>
                <li>
                  <Link exact="true" to={route("root")}>
                    HOME
                  </Link>
                </li>
                <li>
                  <Link to={route("products")}>PRODOTTI</Link>
                </li>
                <li>
                  <Link to={route("story")}>STORIA</Link>
                </li>
                <li>
                  <Link to={route("contacts")}>CONTATTI</Link>
                </li>
                <li>
                  <Link to={route("erica")}>ERICA</Link>
                </li>
                <li>
                  <a
                    href="http://www.facebook.com/lericas?ref=ts&fref=ts"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="facebook-link"
                  >
                    <img
                      src={images.facebook}
                      width="35"
                      height="35"
                      border="0"
                      alt="img"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/luigilericas/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={images.instagram}
                      width="35"
                      height="35"
                      border="0"
                      alt="img"
                    />
                  </a>
                </li>
              </ul>
            </nav>
          </footer>
        </Responsive>
        <Responsive device="not-desktop">
          <footer className="bottom-menu-mobile">
            <section className="logo">
              <Link to={route("root")}>
                <img src={images.logo} alt="img" />
              </Link>
            </section>
            <section className="title">
              <h2>Lericas</h2>
              <h3>di Strazza Luigi</h3>
            </section>
            <nav>
              <a
                href="http://www.facebook.com/lericas?ref=ts&fref=ts"
                target="_blank"
                rel="noopener noreferrer"
                className="facebook-link"
              >
                <img
                  src={images.facebook}
                  width="30"
                  height="30"
                  border="0"
                  alt="img"
                />
              </a>
              <a
                href="https://www.instagram.com/luigilericas/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={images.instagram}
                  width="30"
                  height="30"
                  border="0"
                  alt="img"
                />
              </a>
            </nav>
          </footer>
        </Responsive>
      </div>
    )
  }
}

export default BottomMenu
