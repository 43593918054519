import carport1 from "./products/carport/1.jpg"
import carport2 from "./products/carport/2.jpg"
import carport3 from "./products/carport/3.png"
import carport4 from "./products/carport/4.jpg"
import carport5 from "./products/carport/5.jpg"
import carport6 from "./products/carport/6.jpg"
import gazebo1 from "./products/gazebo/1.jpg"
import gazebo2 from "./products/gazebo/2.jpg"
import gazebo3 from "./products/gazebo/3.jpg"
import gazebo4 from "./products/gazebo/4.jpg"
import gazebo5 from "./products/gazebo/5.jpg"
import gazebo6 from "./products/gazebo/6.jpg"
import gazebo7 from "./products/gazebo/7.jpg"
import gazebo8 from "./products/gazebo/8.jpg"
import gazebo9 from "./products/gazebo/9.jpg"
import gazebo10 from "./products/gazebo/10.jpg"
import gazebo11 from "./products/gazebo/11.jpg"
import gazebo12 from "./products/gazebo/12.jpg"
import gazebo13 from "./products/gazebo/13.jpg"
import scope1 from "./products/scope/1.jpg"
import scope2 from "./products/scope/2.jpg"
import scope3 from "./products/scope/3.jpg"
import scope4 from "./products/scope/4.jpg"
import scope5 from "./products/scope/5.jpg"
import arredamento1 from "./products/arredamento/1.jpg"
import arredamento2 from "./products/arredamento/2.jpg"
import arredamento3 from "./products/arredamento/3.jpg"
import arredamento4 from "./products/arredamento/4.jpg"
import recinzione1 from "./products/recinzione/1.jpg"
import recinzione2 from "./products/recinzione/2.jpg"
import recinzione3 from "./products/recinzione/3.jpg"
import recinzione4 from "./products/recinzione/4.jpg"
import recinzione5 from "./products/recinzione/5.jpg"
import recinzione6 from "./products/recinzione/6.jpg"
import recinzione7 from "./products/recinzione/7.jpg"
import recinzione8 from "./products/recinzione/8.jpg"
import recinzione9 from "./products/recinzione/9.jpg"
import cancello1 from "./products/cancello/1.jpg"
import cancello2 from "./products/cancello/2.jpg"
import cancello3 from "./products/cancello/3.jpg"
import palizzata1 from "./products/palizzata/1.jpg"
import palizzata2 from "./products/palizzata/2.jpg"
import palizzata3 from "./products/palizzata/3.jpg"
import palizzata4 from "./products/palizzata/4.jpg"
import palizzata5 from "./products/palizzata/5.jpg"
import palizzata6 from "./products/palizzata/6.jpg"
import palizzata7 from "./products/palizzata/7.jpg"
import palizzata8 from "./products/palizzata/8.jpg"
import palizzata9 from "./products/palizzata/9.jpg"
import scala1 from "./products/scala/1.jpg"
import scala2 from "./products/scala/2.jpg"
import scala3 from "./products/scala/3.jpg"
import scala4 from "./products/scala/4.jpg"
import scala5 from "./products/scala/5.jpg"
import scala6 from "./products/scala/6.jpg"
import scala7 from "./products/scala/7.jpg"
import tavolo1 from "./products/tavolo/1.jpg"
import tavolo2 from "./products/tavolo/2.jpg"
import tavolo3 from "./products/tavolo/3.jpg"
import staccionata1 from "./products/staccionata/1.jpg"
import staccionata2 from "./products/staccionata/2.jpg"
import staccionata3 from "./products/staccionata/3.jpg"
import staccionata4 from "./products/staccionata/4.jpg"
import staccionata5 from "./products/staccionata/5.jpg"
import staccionata6 from "./products/staccionata/6.jpg"
import grigliato1 from "./products/grigliato/1.jpg"
import grigliato2 from "./products/grigliato/2.jpg"
import grigliato3 from "./products/grigliato/3.jpg"
import casetta1 from "./products/casetta/1.jpg"
import casetta2 from "./products/casetta/2.jpg"
import casetta3 from "./products/casetta/3.jpg"
import casetta4 from "./products/casetta/4.jpg"
import casetta5 from "./products/casetta/5.jpg"
import pergola1 from "./products/pergola/1.jpg"
import pergola2 from "./products/pergola/2.jpg"
import pergola3 from "./products/pergola/3.jpg"
import pergola4 from "./products/pergola/4.jpg"
import pergola5 from "./products/pergola/5.jpg"
import pergola6 from "./products/pergola/6.jpg"
import pergola7 from "./products/pergola/7.jpg"
import pergola8 from "./products/pergola/8.jpg"
import ombrellone1 from "./products/ombrellone/1.jpg"
import ombrellone2 from "./products/ombrellone/2.jpg"
import ombrellone3 from "./products/ombrellone/3.jpg"
import ombrellone4 from "./products/ombrellone/4.jpg"
import ombrellone5 from "./products/ombrellone/5.jpg"
import ombrellone6 from "./products/ombrellone/6.jpg"
import ombrellone7 from "./products/ombrellone/7.jpg"
import ombrellone8 from "./products/ombrellone/8.jpg"
import ombrellone9 from "./products/ombrellone/9.jpg"
import ombrellone10 from "./products/ombrellone/10.jpg"
import coperture1 from "./products/coperture/1.jpg"
import coperture2 from "./products/coperture/2.jpg"
import coperture3 from "./products/coperture/3.jpg"
import coperture4 from "./products/coperture/4.jpg"
import coperture5 from "./products/coperture/5.jpg"
import erica1 from "./products/erica/1.jpg"
import transport from "./products/services/transport.jpg"
import montaggio from "./products/services/montaggio.jpg"
import design from "./products/services/design.jpg"
import ricopertura from "./products/services/ricopertura.jpg"
import rinfoltitura from "./products/services/rinfoltitura.jpg"
import customization from "./products/services/customization.jpg"

import logo from "./logo/logo.png"
import favicon from "./logo/favicon.png"
import luigi from "./luigi.jpg"
import facebook from "./social/facebook.png"
import instagram from "./social/instagram.png"

import granathakids from "./erica/granathakids.png"

export default {
  carport1,
  carport2,
  carport3,
  carport4,
  carport5,
  carport6,
  gazebo1,
  gazebo2,
  gazebo3,
  gazebo4,
  gazebo5,
  gazebo6,
  gazebo7,
  gazebo8,
  gazebo9,
  gazebo10,
  gazebo11,
  gazebo12,
  gazebo13,
  scope1,
  scope2,
  scope3,
  scope4,
  scope5,
  arredamento1,
  arredamento2,
  arredamento3,
  arredamento4,
  recinzione1,
  recinzione2,
  recinzione3,
  recinzione4,
  recinzione5,
  recinzione6,
  recinzione7,
  recinzione8,
  recinzione9,
  cancello1,
  cancello2,
  cancello3,
  palizzata1,
  palizzata2,
  palizzata3,
  palizzata4,
  palizzata5,
  palizzata6,
  palizzata7,
  palizzata8,
  palizzata9,
  scala1,
  scala2,
  scala3,
  scala4,
  scala5,
  scala6,
  scala7,
  tavolo1,
  tavolo2,
  tavolo3,
  staccionata1,
  staccionata2,
  staccionata3,
  staccionata4,
  staccionata5,
  staccionata6,
  grigliato1,
  grigliato2,
  grigliato3,
  casetta1,
  casetta2,
  casetta3,
  casetta4,
  casetta5,
  pergola1,
  pergola2,
  pergola3,
  pergola4,
  pergola5,
  pergola6,
  pergola7,
  pergola8,
  ombrellone1,
  ombrellone2,
  ombrellone3,
  ombrellone4,
  ombrellone5,
  ombrellone6,
  ombrellone7,
  ombrellone8,
  ombrellone9,
  ombrellone10,
  coperture1,
  coperture2,
  coperture3,
  coperture4,
  coperture5,
  erica1,

  transport,
  montaggio,
  design,
  ricopertura,
  rinfoltitura,
  customization,

  logo,
  favicon,
  luigi,
  facebook,
  instagram,

  granathakids,
}
